export default function Footer() {
  return (
    <footer>
      <p>
        Beth-El Mennonite Church, 4652 Ranch Drive, Colorado Springs, CO 80918
      </p>
      <p>Email: coloradogardens@gmail.com</p>
      <p>©2023 Ranch Community Garden. All Rights Reserved</p>
    </footer>
  );
}
